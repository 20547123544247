<script>
  import Layout from "../../layouts/auth";
  import { authMethods } from "@/state/helpers";
  import appConfig from "@/app.config";

  /**
   * Login component
   */
  export default {
    page: {
      title: "Recuperar Senha",
      meta: [{ name: "description", content: appConfig.description }],
    },
    components: { Layout },
    data() {
      return {
        email: "",
        password: "",
        authError: null,
        authSuccess: null,
        tryingToForgot: false,
        isAuthError: false,
        isAuthSuccess: false,
      };
    },
    methods: {
      ...authMethods,
      // Try to log the user in with the username
      // and password they provided.
      tryToForgot() {
        this.tryingToForgot = true;
        // Reset the authError if it existed.
        this.authError = null;
        return (
          this.forgotPassword({
            email: this.email
          })
            // eslint-disable-next-line no-unused-vars
            .then(user => {
              this.tryingToForgot = false;
              this.isAuthError = false;
              this.isAuthSuccess = true;
              this.authSuccess = "Operação realizada com sucesso. Em instantes vocẽ receberá em sua caixa de entrada instruções para a recuperação de sua senha.";
            })
            .catch(error => {
              this.tryingToForgot = false;
              this.authError = error ? error : "";
              this.isAuthError = true;
              this.isAuthSuccess = false;
            })
        );
      },
    },
  };
</script>

<template>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div style="background-color: rgba(85, 110, 230, 0.15)">
            <div class="row">
              <div class="col-6">
                <div class="text-primary p-4">
                  <h5 class="text-primary">
                    Bem-vindo!
                  </h5>
                  <p>Utilize o formulário abaixo para recuperar a sua senha.</p>
                </div>
              </div>
              <div class="col-5 align-self-end">
                <img
                  src="@/assets/images/partners-img.png"
                  alt
                  class="img-fluid"
                >
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div>
              <router-link
                tag="a"
                to="/"
              >
                <div class="avatar-md profile-user-wid mb-4">
                  <span class="avatar-title rounded-circle bg-light">
                    <img
                      src="@/assets/images/logo-dark.svg"
                      alt
                      width="48"
                    >
                  </span>
                </div>
              </router-link>
            </div>
            
            <b-alert
              v-model="isAuthError"
              variant="danger"
              class="mt-3"
              dismissible
            >
              {{ authError }}
            </b-alert>
  
            <b-alert
              v-model="isAuthSuccess"
              variant="success"
              class="mt-3"
              dismissible
            >
              {{ authSuccess }}
            </b-alert>
            
            <b-form
              class="p-2"
              @submit.prevent="tryToForgot"
            >
              <b-form-group
                id="input-group-1"
                label="Email"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="email"
                  type="text"
                  placeholder=""
                />
              </b-form-group>
              
              <div class="mt-3">
                <b-button
                  type="submit"
                  variant="primary"
                  class="btn-block"
                >
                  Enviar
                </b-button>
              </div>
              <div class="mt-4 text-center">
                <router-link
                  tag="a"
                  to="/login"
                  class="text-muted"
                >
                  <i class="mdi mdi-login mr-1" /> Voltar para o login
                </router-link>
              </div>
            </b-form>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
        
        <div class="mt-5 text-center">
          <p>
            Não possui cadastro?
            <a
              href="https://go.huggy.io/parceiros"
              class="font-weight-medium text-primary"
              target="_blank"
            >Criar uma conta</a>
          </p>
          <p>
            © {{ new Date().getFullYear() }} Huggy.
          </p>
        </div>
        <!-- end row -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<style lang="scss" module></style>
